var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.activeUserInfo ? _c('BaseRow', [_c('BaseInfoCol', {
    attrs: {
      "label": "Fornavn:",
      "data-cy": "firstName"
    }
  }, [_vm._v(" " + _vm._s(_vm.activeUserInfo.customer.firstName) + " ")]), _c('BaseInfoCol', {
    attrs: {
      "label": "Etternavn:",
      "data-cy": "lastName"
    }
  }, [_vm._v(" " + _vm._s(_vm.activeUserInfo.customer.lastName) + " ")]), _c('BaseInfoCol', {
    attrs: {
      "label": "E-post:",
      "data-cy": "email"
    }
  }, [_vm._v(" " + _vm._s(_vm.activeUserInfo.email) + " ")]), _c('BaseInfoCol', {
    attrs: {
      "label": "Mobilnummer:",
      "data-cy": "mobileNumber"
    }
  }, [_vm._v(" " + _vm._s(_vm.activeUserInfo.mobileNumber) + " ")]), _c('BaseInfoCol', {
    attrs: {
      "label": "Adresse:",
      "data-cy": "postAddress1"
    }
  }, [_vm._v(" " + _vm._s(_vm.activeUserInfo.postAddress1) + " ")]), _c('BaseInfoCol', {
    attrs: {
      "label": "Postnummer:",
      "data-cy": "postZip"
    }
  }, [_vm._v(" " + _vm._s(_vm.activeUserInfo.postZip) + " ")]), _c('BaseInfoCol', {
    attrs: {
      "label": "Poststed:",
      "data-cy": "postCity"
    }
  }, [_vm._v(" " + _vm._s(_vm.activeUserInfo.postCity) + " ")]), _c('BaseInfoCol', {
    attrs: {
      "label": "Bekreft at opplysninger stemmer:",
      "oneLine": true
    }
  }, [_c('v-checkbox', {
    attrs: {
      "rules": [_vm.validateChecked],
      "true-value": true,
      "false-value": false,
      "data-cy": "hasVerifiedContactInfo",
      "label": "Jeg bekrefter at opplysningene stemmer (opplysningene kan redigeres på din profil) *"
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.applicationModel.costBearerPrivate.hasVerifiedContactInfo,
      callback: function ($$v) {
        _vm.$set(_vm.applicationModel.costBearerPrivate, "hasVerifiedContactInfo", $$v);
      },
      expression: "applicationModel.costBearerPrivate.hasVerifiedContactInfo"
    }
  })], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }