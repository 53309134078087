import { securedHttpClient } from "@/api/httpClient";
import { BrregApiSubUnitResponse, BrregApiUnitResponse } from "./brreg.interface";

const brregApi = "https://data.brreg.no/enhetsregisteret/api";

export const getMainOrganizationsFromBrreg = (params: { name?: string; orgNr?: string }) => {
  const response = securedHttpClient.get<BrregApiUnitResponse>(`${brregApi}/enheter/`, {
    params: {
      navn: params.name,
      organisasjonsnummer: params.orgNr?.length === 9 ? params.orgNr : undefined,
    },
  });
  return response;
};

export const getSubOrganizationsFromBrreg = (params: { name?: string; orgNr?: string }) => {
  const response = securedHttpClient.get<BrregApiSubUnitResponse>(`${brregApi}/underenheter/`, {
    params: {
      navn: params.name,
      organisasjonsnummer: params.orgNr?.length === 9 ? params.orgNr : undefined,
    },
  });
  return response;
};
