var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.courseRegulationModalOpen ? _c('BaseModal', {
    attrs: {
      "maxWidth": "1500px"
    }
  }, [_c('BaseCard', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Kursbestemmelser ")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('v-btn', {
          staticClass: "px-6",
          attrs: {
            "color": "primary",
            "block": ""
          },
          on: {
            "click": function ($event) {
              _vm.courseRegulationModalOpen = false;
            }
          }
        }, [_vm._v(" Lukk ")])];
      },
      proxy: true
    }], null, false, 2953207697)
  }, [_c('iframe', {
    staticClass: "pdfIframe",
    attrs: {
      "src": _vm.pdfFileBlob,
      "type": "application/pdf",
      "width": "100%",
      "height": "100%"
    }
  })])], 1) : _vm._e(), _c('BaseRow', [!_vm.isMobile ? _c('v-col', {
    staticClass: "d-flex align-center"
  }, [_c('v-btn', {
    staticClass: "mr-4",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.courseRegulationModalOpen = true;
      }
    }
  }, [_vm._v(" Les kursbestemmelser ")])], 1) : _vm._e(), _vm.isMobile ? _c('v-dialog', {
    attrs: {
      "fullscreen": "",
      "hide-overlay": "",
      "transition": "dialog-bottom-transition"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-col', {
          staticClass: "d-flex align-center"
        }, [_c('v-btn', _vm._g(_vm._b({
          staticClass: "mr-4",
          attrs: {
            "color": "primary"
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" Les kursbestemmelser ")])], 1)];
      }
    }], null, false, 703299777),
    model: {
      value: _vm.courseRegulationModalOpen,
      callback: function ($$v) {
        _vm.courseRegulationModalOpen = $$v;
      },
      expression: "courseRegulationModalOpen"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "dark": "",
      "color": "primary"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "dark": ""
    },
    on: {
      "click": function ($event) {
        _vm.courseRegulationModalOpen = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1), _c('v-toolbar-title', [_vm._v("Kursbestemmelser")])], 1), _c('iframe', {
    staticClass: "pdfIframe",
    attrs: {
      "src": _vm.pdfFileBlob,
      "type": "application/pdf",
      "width": "100%",
      "height": "100%"
    }
  })], 1)], 1) : _vm._e(), _c('v-col', {
    staticClass: "d-flex align-center"
  }, [_c('v-checkbox', {
    attrs: {
      "rules": [_vm.validateChecked],
      "false-value": false,
      "true-value": true,
      "label": "Jeg har lest og forstått kursbestemmelsene *",
      "data-cy": "agreeAndReadCourseRegulations"
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.applicationModel.courseRegulationsAccepted,
      callback: function ($$v) {
        _vm.$set(_vm.applicationModel, "courseRegulationsAccepted", $$v);
      },
      expression: "applicationModel.courseRegulationsAccepted"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }