
import {
  ApiUpsertApplicationFormResponseDto,
  ApiGetCustomerDto,
  ApiPurchaserOrganizationDto,
  ApiCostBearerOrganizationDto,
} from "@/api/generated/Api";
import BaseCard from "@/components/shared/BaseCard.vue";
import BaseInfoCol from "@/components/shared/BaseInfoCol.vue";
import BaseRow from "@/components/shared/BaseRow.vue";
import { useAutoDeepCloneProp } from "@/fragments/useAutoDeepCloneProp";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import {
  validateBoolean,
  validateIsEmail,
  validateIsNorwegianPhoneNumber,
  validateNotEmpty,
  validateNotEmptyWithCustomMessage,
} from "@/shared/helpers/validationHelpers";
import { computed, defineComponent, PropType, ref, watch } from "@vue/composition-api";
import CostBearerForm from "@/components/courseApply/costBearer/CostBearerForm.vue";

export default defineComponent({
  name: "ApplyCourseCostBearer",
  components: { BaseCard, BaseInfoCol, BaseRow, CostBearerForm },
  props: {
    value: {
      type: Object as PropType<ApiUpsertApplicationFormResponseDto>,
      required: true,
    },
    activeUserInfo: {
      type: Object as PropType<ApiGetCustomerDto>,
      required: true,
    },
    isInvoiceReference: {
      type: Boolean,
      default: false,
    },
    isInstallments: {
      type: Boolean,
      required: true,
    },
    isMonthly: {
      type: Boolean,
      default: false,
    },
    isSemiAnnually: {
      type: Boolean,
      default: false,
    },
    isQuarterly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["input"],
  setup(props, { emit }) {
    const applicationModel = useAutoDeepCloneProp<ApiUpsertApplicationFormResponseDto>(props, "value");
    const hasAnotherInvoiceAddress = ref(false);
    const invoiceReferenceText = "Fakturareferanse må fylles ut iht fakturamottakers regler for fakturareferanse";
    const invoiceReferenceErrorMessage = "Deres referanse eller bestillingsnummer må fylles ut";

    const installmentOptions = [
      {
        label: "Månedlig",
        value: 1,
        isShowable: props.isMonthly,
      },
      {
        label: "Kvartalsvis",
        value: 3,
        isShowable: props.isQuarterly,
      },
      {
        label: "Halvårlig",
        value: 6,
        isShowable: props.isSemiAnnually,
      },
    ];

    watch(hasAnotherInvoiceAddress, () => {
      if (!hasAnotherInvoiceAddress.value) {
        applicationModel.value.costBearerOrganization = {
          isPurchaser: true,
          orderNumber: applicationModel.value.costBearerOrganization?.orderNumber,
          referenceNumber: applicationModel.value.costBearerOrganization?.referenceNumber,
        };
        emitChanges();
      }
    });

    const updatePurchaserOrganization = (purchaserOrganization: ApiPurchaserOrganizationDto) => {
      applicationModel.value.purchaser = purchaserOrganization;
      emitChanges();
    };

    const updateCostBearerOrganization = (costBearerOrganization: ApiCostBearerOrganizationDto) => {
      applicationModel.value.costBearerOrganization = {
        ...costBearerOrganization,
        isPurchaser: costBearerOrganization === undefined,
        orderNumber: applicationModel.value.costBearerOrganization?.orderNumber,
        referenceNumber: applicationModel.value.costBearerOrganization?.referenceNumber,
      };
      emitChanges();
    };

    const emitChanges = () => {
      emit("input", deepCloneObject(applicationModel.value));
    };

    return {
      applicationModel,
      validateNotEmpty,
      validateNotEmptyWithCustomMessage,
      validateIsEmail,
      validateIsNorwegianPhoneNumber,
      emitChanges,
      validateBoolean,
      updatePurchaserOrganization,
      updateCostBearerOrganization,
      filterInstallmentOptions: computed(() => installmentOptions.filter((option) => option.isShowable)),
      invoiceReferenceText,
      invoiceReferenceErrorMessage,
      hasAnotherInvoiceAddress,
    };
  },
});
