<script>
import { defineComponent } from "@vue/composition-api";

// https://github.com/vuetifyjs/vuetify/releases/tag/v2.4.0
// The recommended grid structure, anything else is not officially supported.
export default defineComponent({
  name: "BaseRow",
});
</script>
<template>
  <v-container fluid>
    <v-row>
      <slot />
    </v-row>
  </v-container>
</template>
