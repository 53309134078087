
import { ApiUpsertApplicationFormResponseDto } from "@/api/generated/Api";
import { validateMaxFileSize, validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { FileQuestionType } from "@/shared/types/questions.types";
import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "FileQuestion",
  props: {
    question: {
      type: Object as PropType<FileQuestionType>,
      required: true,
    },
    value: {
      type: Object as PropType<ApiUpsertApplicationFormResponseDto>,
      required: true,
    },
  },
  emits: ["change", "input"],
  setup() {
    return {
      acceptTypes,
      validateMaxFileSize: validateMaxFileSize(2),
      validateNotEmpty,
    };
  },
});

const acceptTypes = ["application/pdf", "image/png", "image/jpeg"];
