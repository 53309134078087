
import {
  ApiApplicationFormTemplateAdditionalQuestionDto,
  ApiUpsertApplicationFormResponseDto,
} from "@/api/generated/Api";
import { useAutoDeepCloneProp } from "@/fragments/useAutoDeepCloneProp";
import { useAutoUpdateAdditionalQuestionModel } from "@/fragments/useAutoUpdateAdditionalQuestionModel";
import { validateMaxLength, validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { defineComponent, PropType, ref, Ref } from "@vue/composition-api";

export default defineComponent({
  name: "TextQuestion",
  props: {
    question: {
      type: Object as PropType<ApiApplicationFormTemplateAdditionalQuestionDto>,
      required: true,
    },
    value: {
      type: Object as PropType<ApiUpsertApplicationFormResponseDto>,
      required: true,
    },
    showQuestion: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["change", "input"],
  setup(props, { emit }) {
    const applicationModel: Ref<ApiUpsertApplicationFormResponseDto> =
      useAutoDeepCloneProp<ApiUpsertApplicationFormResponseDto>(props, "value");
    const textModel = ref("");

    const { emitChanges } = useAutoUpdateAdditionalQuestionModel(textModel, applicationModel, props.question.id, emit);

    const getQuestionRules = () => {
      const rules = [validateMaxLength(200)];
      if (props.showQuestion && props.question.required) {
        return [...rules, validateNotEmpty];
      }
      return rules;
    };

    return {
      textModel,
      emitChanges,
      getQuestionRules,
    };
  },
});
