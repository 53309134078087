var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseRow', [_c('BaseInfoCol', {
    attrs: {
      "label": "Organisasjon: *"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "data-cy": "selectedOrganization",
      "items": _vm.organizationsFound,
      "loading": _vm.isLoadingOrganizations,
      "search-input": _vm.organizationsSearchString,
      "label": "Skriv inn organisasjonsnavn/organisasjons nr (9 siffer)",
      "clearable": "",
      "return-object": "",
      "item-text": _vm.organizationDisplayName,
      "item-value": "organizationNumber",
      "rules": [_vm.validateNotEmpty]
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.organizationsSearchString = $event;
      },
      "update:search-input": function ($event) {
        _vm.organizationsSearchString = $event;
      },
      "change": _vm.onOrganizationChange
    },
    model: {
      value: _vm.selectedOrganization,
      callback: function ($$v) {
        _vm.selectedOrganization = $$v;
      },
      expression: "selectedOrganization"
    }
  })], 1), _vm.selectedOrganization ? _c('BaseInfoCol', {
    attrs: {
      "label": "Organisasjonsnummer: *"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": "",
      "label": "Organisasjonsnummer"
    },
    model: {
      value: _vm.selectedOrganization.organizationNumber,
      callback: function ($$v) {
        _vm.$set(_vm.selectedOrganization, "organizationNumber", $$v);
      },
      expression: "selectedOrganization.organizationNumber"
    }
  })], 1) : _vm._e(), _vm.selectedOrganization ? _c('BaseInfoCol', {
    attrs: {
      "label": "Adresse: *"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": !_vm.selectedOrganizationAddressMissing,
      "rules": [_vm.validateNotEmpty],
      "label": "Adresse"
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.selectedOrganization.address,
      callback: function ($$v) {
        _vm.$set(_vm.selectedOrganization, "address", $$v);
      },
      expression: "selectedOrganization.address"
    }
  })], 1) : _vm._e(), _vm.selectedOrganization ? _c('BaseInfoCol', {
    attrs: {
      "label": "Postnummer: *"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": !_vm.selectedOrganizationAddressMissing,
      "rules": [_vm.validateNotEmpty, _vm.validateZipCode],
      "label": "Postnummer"
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.selectedOrganization.zip,
      callback: function ($$v) {
        _vm.$set(_vm.selectedOrganization, "zip", $$v);
      },
      expression: "selectedOrganization.zip"
    }
  })], 1) : _vm._e(), _vm.selectedOrganization ? _c('BaseInfoCol', {
    attrs: {
      "label": "Poststed: *"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": !_vm.selectedOrganizationAddressMissing,
      "rules": [_vm.validateNotEmpty],
      "label": "Poststed"
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.selectedOrganization.city,
      callback: function ($$v) {
        _vm.$set(_vm.selectedOrganization, "city", $$v);
      },
      expression: "selectedOrganization.city"
    }
  })], 1) : _vm._e(), _vm.selectedOrganization ? _c('BaseInfoCol', {
    attrs: {
      "label": "Mobilnummer: *"
    }
  }, [_c('v-text-field', {
    attrs: {
      "data-cy": "organizationMobileNumber",
      "rules": [_vm.validateNotEmpty, _vm.validateIsNorwegianPhoneNumber],
      "label": "Mobilnummer"
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.organizationMobileNumber,
      callback: function ($$v) {
        _vm.organizationMobileNumber = $$v;
      },
      expression: "organizationMobileNumber"
    }
  })], 1) : _vm._e(), _vm.selectedOrganization ? _c('BaseInfoCol', {
    attrs: {
      "label": "Epost for fakturamottak:",
      "icon": "mdi-message-question-outline",
      "iconText": "Oppgi epostadressen som faktura skal sendes til"
    }
  }, [_c('v-text-field', {
    attrs: {
      "data-cy": "organizationEmail",
      "rules": _vm.organizationEmail ? [_vm.validateIsEmail] : [],
      "label": "Epost for fakturamottak"
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.organizationEmail,
      callback: function ($$v) {
        _vm.organizationEmail = $$v;
      },
      expression: "organizationEmail"
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }