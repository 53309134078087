
import { ApiUpsertApplicationFormResponseDto } from "@/api/generated/Api";
import { useAutoDeepCloneProp } from "@/fragments/useAutoDeepCloneProp";
import { useAutoUpdateAdditionalQuestionModel } from "@/fragments/useAutoUpdateAdditionalQuestionModel";
import { MultipleChoiceQuestionType } from "@/shared/types/questions.types";
import { defineComponent, PropType, Ref, ref } from "@vue/composition-api";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";

export default defineComponent({
  name: "MultipleChoiceQuestion",
  props: {
    question: {
      type: Object as PropType<MultipleChoiceQuestionType>,
      required: true,
    },
    value: {
      type: Object as PropType<ApiUpsertApplicationFormResponseDto>,
      required: true,
    },
  },
  emits: ["change", "input"],
  setup(props, { emit }) {
    const applicationModel: Ref<ApiUpsertApplicationFormResponseDto> =
      useAutoDeepCloneProp<ApiUpsertApplicationFormResponseDto>(props, "value");

    const questionAlternatives = props.question.questionAlternatives;
    const multipleChoiceModel = ref("");

    const { emitChanges } = useAutoUpdateAdditionalQuestionModel(
      multipleChoiceModel,
      applicationModel,
      props.question.id,
      emit
    );

    return {
      emitChanges,
      validateNotEmpty,
      multipleChoiceModel,
      questionAlternatives,
    };
  },
});
