
import { api } from "@/api/api";
import { ApiSsbCountryDto, ApiUpsertApplicationFormResponseDto } from "@/api/generated/Api";
import BaseCard from "@/components/shared/BaseCard.vue";
import BaseInfoCol from "@/components/shared/BaseInfoCol.vue";
import BaseRow from "@/components/shared/BaseRow.vue";
import { useAutoDeepCloneProp } from "@/fragments/useAutoDeepCloneProp";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { validateNotEmpty, validateBoolean } from "@/shared/helpers/validationHelpers";
import { defineComponent, onMounted, PropType, ref } from "@vue/composition-api";
import { validateNorwegianIdNumber } from "norwegian-national-id-validator";

export default defineComponent({
  name: "ApplyCoursePersonalInfo",
  components: { BaseCard, BaseInfoCol, BaseRow },
  props: {
    value: {
      type: Object as PropType<ApiUpsertApplicationFormResponseDto>,
      required: true,
    },
    isCitizenship: {
      type: Boolean,
      required: true,
    },
    isPersonalNumber: {
      type: Boolean,
      required: true,
    },
    isNorwegianMotherTongue: {
      type: Boolean,
      required: true,
    },
    isNorwegianCertificationLevel: {
      type: Boolean,
      required: true,
    },
    isNorwegianSkills: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["input"],
  setup(props, { emit }) {
    const applicationModel = useAutoDeepCloneProp<ApiUpsertApplicationFormResponseDto>(props, "value");
    const countries = ref<ApiSsbCountryDto[]>([]);
    const languageLevels = ref<string[]>([]);
    const norwegianCitizenship = "000";

    const validSSN = (ssn: string) => {
      if (!ssn) {
        return "Fødselsnummer må fylles ut";
      }
      return validateNorwegianIdNumber(ssn) || "Fødselsnummer er ikke gyldig format";
    };

    const emitChanges = () => {
      applicationModel.value.isNorwegianCitizen =
        props.isCitizenship && applicationModel.value.citizenship === norwegianCitizenship;
      emit("input", deepCloneObject(applicationModel.value));
    };

    onMounted(async () => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        countries.value = (await api.ssb.getCountriesAsync()).data;
        languageLevels.value = (await api.curriculum.getCurriculumDefaultoptionsAsync()).data.cefrLevels ?? [];
      });
    });

    return {
      applicationModel,
      countries,
      validateNotEmpty,
      emitChanges,
      validSSN,
      validateBoolean,
      languageLevels,
      norwegianSkillOptions: ["Mindre bra", "Bra", "Svært bra"],
    };
  },
});
