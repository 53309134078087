
import { api } from "@/api/api";
import BaseCard from "@/components/shared/BaseCard.vue";
import BaseInfoCol from "@/components/shared/BaseInfoCol.vue";
import BaseRow from "@/components/shared/BaseRow.vue";
import BaseModal from "@/components/shared/modal/BaseModal.vue";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { generateUuid } from "@/shared/helpers/uuidHelpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { ApplyCourseFile } from "@/shared/interfaces/ApplyCourseFile.interface";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, onMounted, PropType, ref } from "@vue/composition-api";

const customCols = {
  cols: 12,
  xs: 12,
  sm: 12,
  md: 6,
  lg: 4,
  xl: 4,
};

const FileTypes = ["application/pdf", "image/png", "image/jpeg", "image/jpg"];

export default defineComponent({
  name: "ApplyCourseFiles",
  components: { BaseCard, BaseModal, BaseInfoCol, BaseRow },
  props: {
    courseFiles: {
      type: Array as PropType<ApplyCourseFile[]>,
      required: true,
    },
  },
  emits: ["newFile", "removeFile"],
  setup(props, { emit, refs }) {
    const store = useStore<StoreState>();
    const showAddFileModal = ref(false);
    const modalSelectedFile = ref<File>();
    const modalFileDescription = ref("");
    const modalSelectedCategory = ref("");
    const fileCategories = ref<string[]>([]);

    const openNewFileModal = () => {
      showAddFileModal.value = true;
      modalSelectedFile.value = undefined;
      modalFileDescription.value = "";
      modalSelectedCategory.value = "";
    };

    const ILLEGAL_CHARS_REGEX = /[^\s\w.@-]+/g;
    const sanitizeFilename = (filename: string) => filename.replace(ILLEGAL_CHARS_REGEX, "-");

    const addModalFile = () => {
      const isValid = getValidatableRef(refs.connectForm)?.validate();
      if (!isValid) {
        return;
      }

      const selectedFileType = modalSelectedFile.value?.type || "";
      if (!FileTypes.includes(selectedFileType)) {
        openNotification(
          store,
          NotificationItemType.Error,
          "Feil i filformat. Gyldige filformater er PDF, PNG, JPEG og JPG"
        );
        return;
      }

      if (!modalSelectedFile.value) {
        openNotification(
          store,
          NotificationItemType.Error,
          "Noe gikk feil under velging av fil, dette skal ikke skje!"
        );
        return;
      }

      const updatedFile = new File([modalSelectedFile.value], sanitizeFilename(modalSelectedFile.value?.name), {
        type: modalSelectedFile.value?.type,
        lastModified: modalSelectedFile.value?.lastModified,
      });

      const newFile = {
        file: updatedFile,
        description: modalFileDescription.value,
        category: modalSelectedCategory.value,
        id: generateUuid(),
      };
      emit("newFile", newFile);
      showAddFileModal.value = false;
    };

    const removeFile = (file: ApplyCourseFile) => {
      emit("removeFile", file);
    };

    const fetchCourseFileCategories = async () => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        const response = await api.file.getMinSideFileDefaultOption();
        if (!response.data.categories) {
          return;
        }
        fileCategories.value = response.data.categories;
      });
    };

    onMounted(() => {
      fetchCourseFileCategories();
    });

    return {
      customCols,
      addModalFile,
      removeFile,
      openNewFileModal,
      validateNotEmpty,
      showAddFileModal,
      modalSelectedFile,
      modalFileDescription,
      modalSelectedCategory,
      fileCategories,
    };
  },
});
