
import { api } from "@/api/api";
import {
  ApiApplicationFormTemplateAdditionalQuestionDto,
  ApiGetApplicationFormTemplateDto,
  ApiGetAreaDto,
  ApiGetCustomerDto,
  ApiGetMemberOrganizationFeatureSettingDto,
  ApiGetMinSideCourseDto,
  ApiUpsertApplicationFormResponseDto,
} from "@/api/generated/Api";
import ApplyCourseAdditionalQuestions from "@/components/courseApply/ApplyCourseAdditionalQuestions.vue";
import ApplyCourseCompetency from "@/components/courseApply/ApplyCourseCompetency.vue";
import ApplyCourseCostBearer from "@/components/courseApply/ApplyCourseCostBearer.vue";
import ApplyCourseEmployerInfo from "@/components/courseApply/ApplyCourseEmployerInfo.vue";
import ApplyCourseFiles from "@/components/courseApply/ApplyCourseFiles.vue";
import ApplyCoursePersonalInfo from "@/components/courseApply/ApplyCoursePersonalInfo.vue";
import ApplyCourseRegulations from "@/components/courseApply/ApplyCourseRegulations.vue";
import ApplyCourseUnion from "@/components/courseApply/ApplyCourseUnion.vue";
import ApplyCourseUserInfo from "@/components/courseApply/ApplyCourseUserInfo.vue";
import BaseCard from "@/components/shared/BaseCard.vue";
import BaseInfoCol from "@/components/shared/BaseInfoCol.vue";
import BaseRow from "@/components/shared/BaseRow.vue";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { formatDate } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { validateBoolean } from "@/shared/helpers/validationHelpers";
import { ApplyCourseFile } from "@/shared/interfaces/ApplyCourseFile.interface";
import { QuestionValueType } from "@/shared/types/questions.types";
import { useRoute, useRouter, useStore } from "@/shared/useHelpers";
import {
  getInitialApplicationModel,
  setInitialApplicationFormTemplate,
  setInitialCourse,
} from "@/shared/utils/seedData";
import { StoreState } from "@/store/store.state.interface";
import { useGtm } from "@gtm-support/vue2-gtm";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import { isBefore } from "date-fns";

export default defineComponent({
  name: "ApplyCoursePage",
  components: {
    BaseCard,
    BaseInfoCol,
    BaseRow,
    ApplyCoursePersonalInfo,
    ApplyCourseEmployerInfo,
    ApplyCourseCostBearer,
    ApplyCourseUserInfo,
    ApplyCourseUnion,
    ApplyCourseCompetency,
    ApplyCourseRegulations,
    ApplyCourseAdditionalQuestions,
    ApplyCourseFiles,
  },
  setup(_, { refs }) {
    const store = useStore<StoreState>();
    const route = useRoute();
    const router = useRouter();
    const course = ref<ApiGetMinSideCourseDto>(setInitialCourse());
    const activeUserInfo = ref<ApiGetCustomerDto>();
    const applicationFormTemplate = ref<ApiGetApplicationFormTemplateDto>(setInitialApplicationFormTemplate());
    const applicationModel = ref<ApiUpsertApplicationFormResponseDto>(getInitialApplicationModel());
    const courseFiles = ref<ApplyCourseFile[]>([]);
    const courseArea = ref<ApiGetAreaDto>();
    const dataLoaded = ref(false);
    const alreadyApplied = ref(false);
    const alreadyExpired = ref(false);
    const activeFeatureSettings = ref<ApiGetMemberOrganizationFeatureSettingDto[]>([]);

    const getActiveFeatureSettings = async () => {
      const response = await api.organization.getActivatedMemberOrganizationFeatureSettings();
      activeFeatureSettings.value = response.data;
    };

    /* GOOGLE TAG MANAGER */
    const gtm = useGtm();

    const triggerGTMEvent = () => {
      if (!gtm) {
        return;
      }
      gtm.trackEvent({
        event: "submit",
        category: "påmelding",
        action: "sendt skjema",
        label: course.value ? course.value.externalTitle : "Kursnavn",
        noninteraction: false,
      });
    };
    /* GOOGLE TAG MANAGER */

    const mapAdditionalQuestionsInitialValuesToModel = (current: ApiApplicationFormTemplateAdditionalQuestionDto) => {
      const getDefaultResponse = (type: QuestionValueType) => {
        if (
          type === QuestionValueType.Boolean ||
          type === QuestionValueType.Text ||
          type === QuestionValueType.MultipleChoice
        ) {
          return "";
        }
        // TODO file upload, QuestionValueType.File for later implementation
        return null;
      };
      return {
        response: getDefaultResponse(current.type as QuestionValueType),
        questionId: current.id,
      };
    };

    const loadFormAndCourse = async () => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        course.value = (await api.minside.getCourseByIdAsync(+route.params.courseId)).data;
        activeUserInfo.value = (await api.minside.getCustomerPersonCurrentAsync()).data;

        const applicationFormId = course.value.webDescription?.templateId;
        if (!applicationFormId) {
          openNotification(store, NotificationItemType.Error, "Finner ikke påmeldingskjema, dette skal ikke skje!");
          return;
        }

        applicationFormTemplate.value = (await api.appformtemplate.getAppFormTemplateAsync(applicationFormId)).data;
        if (!applicationFormTemplate.value.isActive) {
          openNotification(store, NotificationItemType.Error, "Påmeldingsskjemaet er inaktiv", 8000);
          return;
        }

        const enrollmentDeadline = course.value.enrollmentDeadline
          ? new Date(course.value.enrollmentDeadline).setHours(0, 0, 0, 0)
          : null;
        const todayDate = new Date().setHours(0, 0, 0, 0);

        alreadyExpired.value = enrollmentDeadline ? isBefore(enrollmentDeadline, todayDate) : false;

        if (alreadyExpired.value) {
          openNotification(store, NotificationItemType.Error, "Påmeldingsfristen er utløpt", 8000);
        }

        const areaId = course.value.courseLocation?.areaId;
        if (areaId && areaId > 0) {
          courseArea.value = (await api.area.getAreaByIdAsync(areaId)).data;
        }

        applicationModel.value.templateId = applicationFormId;
        applicationModel.value.regulationsFileId = applicationFormTemplate.value.regulationFileId;
        applicationModel.value.additionalQuestions = applicationFormTemplate.value.additionalQuestions?.map(
          mapAdditionalQuestionsInitialValuesToModel
        );

        dataLoaded.value = true;
      });
    };

    const uploadCourseFiles = (courseID: number) => {
      courseFiles.value.forEach(async (file, index) => {
        globalLoadingWrapper({ type: LoadingType.Spinner, blocking: true }, async () => {
          await api.file.createAppFormResponseFileByIdAsync(courseID, {
            File: file.file,
            Category: file.category,
            Description: file.description,
          });
          openNotification(
            store,
            NotificationItemType.Success,
            `Fil ${index + 1} av ${courseFiles.value.length} er lastet opp`,
            8000
          );
        });
      });
    };

    const handleValidationError = () => {
      const el = document.querySelector(".v-input.error--text");
      el?.scrollIntoView();
      openNotification(
        store,
        NotificationItemType.Error,
        "Kunne ikke sende inn søknaden, et eller flere felter i skjemaet mangler eller inneholder ugyldige verdier",
        8000
      );
    };

    const submitApplication = async () => {
      const isValid = await getValidatableRef(refs.connectForm)?.validate();
      if (!isValid) {
        return handleValidationError();
      }

      if (applicationModel.value.isCostBearerPrivate && applicationFormTemplate.value?.isInstallments) {
        applicationModel.value = {
          ...applicationModel.value,
          costBearerPrivate: {
            ...applicationModel.value.costBearerPrivate,
            // installmentType: SinglePayment=0, IntervalDownpayments=1, PercentageDownpayments=2
            // percentageDownpayments is not implemented yet
            installmentType: applicationModel.value.costBearerPrivate?.installmentInterval ? 1 : 0,
          },
          costBearerOrganization: undefined,
          purchaser: undefined,
        };
      } else {
        applicationModel.value = {
          ...applicationModel.value,
          costBearerPrivate: {
            installmentInterval: null,
            installmentType: null,
            hasVerifiedContactInfo: true,
          },
        };
      }

      globalLoadingWrapper({ type: LoadingType.Spinner, blocking: true }, async () => {
        const courseId = +route.params.courseId;
        await api.course.createCourseParticipantViaAppFormResponseAsync(courseId, applicationModel.value);
        openNotification(store, NotificationItemType.Success, "Søknad er sendt!", 8000);
        uploadCourseFiles(courseId);

        if (applicationFormTemplate.value?.isCompetence) {
          openNotification(
            store,
            NotificationItemType.Information,
            "Dokumenter for kompetanse kan legges til i etterkant under 'Mine Dokumenter'",
            8000
          );
        }
        triggerGTMEvent();
        router.push("/course");
      });
    };

    const addCourseTempFile = (applyCourseFile: ApplyCourseFile) => {
      courseFiles.value.push(applyCourseFile);
    };

    const removeCourseTempFile = (fileToRemove: ApplyCourseFile) => {
      courseFiles.value = courseFiles.value.filter((current) => current.id !== fileToRemove.id);
    };

    const checkIfAlreadyApplied = async () => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        const myCourses = (await api.course.getCourseParticipantAndCoursesByCurrentUserAsync()).data;
        alreadyApplied.value = myCourses.some((c) => c.courseId === +route.params.courseId);
        if (alreadyApplied.value) {
          openNotification(store, NotificationItemType.Error, "Du har allerede søkt/meldt deg på", 8000);
        }
      });
    };

    onMounted(async () => {
      checkIfAlreadyApplied();
      loadFormAndCourse();
      getActiveFeatureSettings();
    });

    return {
      course,
      addCourseTempFile,
      removeCourseTempFile,
      applicationFormTemplate,
      dataLoaded,
      courseArea,
      formatDate,
      submitApplication,
      applicationModel,
      validateBoolean,
      alreadyApplied,
      alreadyExpired,
      courseFiles,
      activeUserInfo,
      activeFeatureSettings,
    };
  },
});
