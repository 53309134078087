export const signupDescriptions = {
  kompetanse: {
    desktopHeader: "Uformell kompetanse / realkompetanse",
    mobileHeader: "Realkompetanse",
    paragraphOne: `
      All den kompetansen en person har skaffet seg gjennom betalt eller ubetalt arbeid, 
      etterutdanning, kurs, fritidsaktiviteter og annet som kommer i tillegg til den 
      kompetansen man har dokumentert gjennom det formelle utdanningssystemet.`,
    paragraphTwo: `
      Med realkompetanse menes all formell og ikke formell kompetanse
      som en person har opparbeidet seg gjennom skolegang, arbeid og
      fritid. Kandidater som søker opptak på bakgrunn av
      realkompetanse må dokumentere at de har tilegnet seg kompetanse
      tilsvarende videregående opplæring fra studieretning for helse-
      og sosialfag eller aktivitørutdanning. Søknad om opptak på
      bakgrunn av realkompetanse skal være skriftlig og inneholde en
      begrunnet egenvurdering. Realkompetansen vurderes ut fra
      innlevert skriftlig dokumentasjon og utfyllende opplysninger fra
      søkeren. Fagskoleansvarlig studiekonsulent saksbehandler
      søknaden. Daglig leder fatter vedtak om opptak.`,
    secondHeader: "Formell kompetanse",
    paragraphThree: `
      All offentlig godkjent utdanning som leder til formell
      kompetanse. Inkludert grunnskole, moduler, årskurs, fagbrev
      eller studiekompetanse på vgs-nivå (inkludert Lærlingepraksis),
      offentlig godkjent fagskoleutdanning, utdanning som gir
      studiepoeng ved høyskole eller universitet, og godkjent
      videreutdanning som gir spesialisering for profesjoner.`,
  },
  realkompetanse: {
    headerOne: "Opptak på bakgrunn av realkompetanse",
    paragraphOne: `Dersom du ikke fyller kravene for formelt opptak, 
      kan du søke på bakgrunn av realkompetanse. Med realkompetanse menes det 
      all den kompetansen en har opparbeidet seg gjennom arbeid, skolegang og fritid.
      Opptaket vil vurderes på bakgrunn av innsendt gyldig dokumentasjon 
      (se opptakskrav for nærmere bekrivelse), og relevans knyttet til studieplan/læreplan. 
      Om du ønsker utfyllende informasjon om dette, vil du finne dette under opptakskrav, 
      eller du kan kontakte oss.`,
    headerTwo: "Dokumentasjon",
    paragraphTwo: `All dokumentasjon skal være skriftlig. Dokumentasjon på
      realkompetanse gjøres i forhold til: Yrkespraksis i form av
      arbeidsattest eller arbeidsbekreftelse som angir start- og
      sluttidspunkt for arbeidsforholdet, stillingsprosent og/eller
      arbeidstimer i sum eller pr. uke/måned. Uttalelse fra overordnet med
      beskrivelse av arbeidsoppgaver og vurdering av hvordan kandidaten
      har løst disse. Utdanning og kurs i form av vitnemål, kursbevis,
      kompetansebevis. Hjemmeomsorg for egne barn med spesielle behov.
      Påberopt omsorg for annet familiemedlem skal dokumenteres gjennom
      attest fra sakkyndig eller relevant offentlig myndighet.`,
    headerThree: "Last opp dokumentasjon",
    paragraphThree: `Dokumentasjon kan lastes opp i bunnen av skjema, 
      evt du kan laste de opp på «MinSide» i etterkant. Dokumentasjon på 
      realkompetanse er kopi av attester fra arbeid, verv mv.`,
  },
  formellkompetanse: {
    headerOne: "Opptak på bakgrunn av formell kompetanse",
    headerTwo: "Last opp dokumentasjon",
  },
};
