
import { ApiUpsertApplicationFormResponseDto } from "@/api/generated/Api";
import BaseCard from "@/components/shared/BaseCard.vue";
import BaseInfoCol from "@/components/shared/BaseInfoCol.vue";
import BaseRow from "@/components/shared/BaseRow.vue";
import { useAutoDeepCloneProp } from "@/fragments/useAutoDeepCloneProp";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { validateBoolean, validateNotEmpty, validateChecked } from "@/shared/helpers/validationHelpers";
import { signupDescriptions } from "@/shared/utils/signupDescriptions";
import { defineComponent, onMounted, PropType, ref, Ref } from "@vue/composition-api";
import BaseModal from "@/components/shared/modal/BaseModal.vue";
import { getFile } from "@/api/file.api";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { LoadingType } from "@/shared/enums/loading-type.enum";

import { isMobile } from "@/shared/helpers/displayHelpers";

export default defineComponent({
  name: "ApplyCourseRegulations",
  components: { BaseCard, BaseInfoCol, BaseRow, BaseModal },
  props: {
    value: {
      type: Object as PropType<ApiUpsertApplicationFormResponseDto>,
      required: true,
    },
    regulationFileId: {
      type: Number,
      required: true,
    },
  },
  emits: ["input"],
  setup(props, { emit }) {
    const applicationModel: Ref<ApiUpsertApplicationFormResponseDto> =
      useAutoDeepCloneProp<ApiUpsertApplicationFormResponseDto>(props, "value");
    const courseRegulationModalOpen = ref(false);
    const pdfFileBlob = ref("");

    const emitChanges = () => {
      emit("input", deepCloneObject(applicationModel.value));
    };

    onMounted(async () => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        const fileResponse = (await getFile(props.regulationFileId)).data;
        pdfFileBlob.value = window.URL.createObjectURL(fileResponse);
      });
    });

    return {
      isMobile,
      applicationModel,
      validateNotEmpty,
      emitChanges,
      validateBoolean,
      signupDescriptions,
      courseRegulationModalOpen,
      pdfFileBlob,
      validateChecked,
    };
  },
});
