
import BaseCard from "@/components/shared/BaseCard.vue";
import BaseInfoCol from "@/components/shared/BaseInfoCol.vue";
import BaseRow from "@/components/shared/BaseRow.vue";
import { getMainOrganizationsFromBrreg, getSubOrganizationsFromBrreg } from "@/api/signup.api";
import { defineComponent, ref, watch } from "@vue/composition-api";
import { debounceHelper } from "@/shared/debounce";
import {
  validateIsEmail,
  validateIsNorwegianPhoneNumber,
  validateNotEmpty,
  validateZipCode,
} from "@/shared/helpers/validationHelpers";
import { BrregUnit } from "@/shared/interfaces/CostBearerForm.interface";

export default defineComponent({
  name: "CostBearerForm",
  components: { BaseCard, BaseInfoCol, BaseRow },
  props: {
    isPurchaserOrganization: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["updatePurchaserOrganization", "updateCostBearerOrganization"],
  setup(props, { emit }) {
    const organizationsFound = ref<BrregUnit[]>([]);
    const isLoadingOrganizations = ref(false);
    const organizationsSearchString = ref("");
    const selectedOrganization = ref<BrregUnit>();
    const selectedOrganizationAddressMissing = ref(false);
    const organizationMobileNumber = ref("");
    const organizationEmail = ref("");

    const organizationDisplayName = (organization: BrregUnit) =>
      `${organization.name} - ${organization.organizationNumber} `;

    watch(organizationsSearchString, () => {
      if (!organizationsSearchString.value) {
        return;
      }
      loadCompanies(organizationsSearchString.value);
    });

    watch(selectedOrganization, () => {
      if (!selectedOrganization.value) {
        organizationMobileNumber.value = "";
        organizationEmail.value = "";
      }
    });

    const isPositiveNumber = (value: string) => {
      if (Number.isInteger(Number(value)) && Number(value) > 0) {
        return true;
      }
      return false;
    };

    const loadCompanies = async (seachQuery: string) => {
      await debounceHelper.debounce("loadCompanies", 500);
      isLoadingOrganizations.value = true;
      const query = isPositiveNumber(seachQuery) ? { orgNr: seachQuery } : { name: seachQuery };

      // org id search only works with exact match
      const [mainOrgResponse, subOrgResponse] = await Promise.all([
        getMainOrganizationsFromBrreg(query),
        getSubOrganizationsFromBrreg(query),
      ]);

      const mainOrgs: BrregUnit[] = mainOrgResponse.data._embedded?.enheter.map((current) => ({
        name: current.navn,
        organizationNumber: current.organisasjonsnummer,
        address: current.forretningsadresse?.adresse?.join(" ") ?? "",
        zip: current.forretningsadresse?.postnummer ?? "",
        city: current.forretningsadresse?.poststed ?? "",
        country: current.forretningsadresse?.land ?? "",
        isBrregSubCompany: false,
      }));

      const subOrgs: BrregUnit[] = subOrgResponse.data._embedded?.underenheter.map((current) => ({
        name: current.navn,
        organizationNumber: current.organisasjonsnummer,
        address: current.beliggenhetsadresse?.adresse?.join(" ") ?? "",
        zip: current.beliggenhetsadresse?.postnummer ?? "",
        city: current.beliggenhetsadresse?.poststed ?? "",
        country: current.beliggenhetsadresse?.land ?? "",
        isBrregSubCompany: true,
      }));

      const mainOrganizations = mainOrgs ? mainOrgs : [];
      const subOrganizations = subOrgs ? subOrgs : [];

      organizationsFound.value = [...mainOrganizations, ...subOrganizations];
      isLoadingOrganizations.value = false;
    };

    const onOrganizationChange = () => {
      const isAddressNonValid =
        !selectedOrganization.value?.address || !selectedOrganization.value?.zip || !selectedOrganization.value?.city;
      selectedOrganizationAddressMissing.value = isAddressNonValid;
      emitChanges();
    };

    const mapOrganizationValues = () => ({
      name: selectedOrganization.value?.name,
      organizationNumber: selectedOrganization.value?.organizationNumber,
      address: selectedOrganization.value?.address,
      zip: selectedOrganization.value?.zip,
      city: selectedOrganization.value?.city,
      country: selectedOrganization.value?.country,
      isBrregSubCompany: selectedOrganization.value?.isBrregSubCompany,
      mobileNumber: organizationMobileNumber.value,
      email: organizationEmail.value,
    });

    const emitChanges = () => {
      const organization = selectedOrganization.value ? mapOrganizationValues() : undefined;
      props.isPurchaserOrganization
        ? emit("updatePurchaserOrganization", organization)
        : emit("updateCostBearerOrganization", organization);
    };

    return {
      organizationDisplayName,
      selectedOrganization,
      selectedOrganizationAddressMissing,
      organizationsFound,
      isLoadingOrganizations,
      organizationsSearchString,
      organizationMobileNumber,
      organizationEmail,
      validateNotEmpty,
      validateIsEmail,
      validateIsNorwegianPhoneNumber,
      validateZipCode,
      onOrganizationChange,
      emitChanges,
    };
  },
});
