var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseRow', [_c('BaseInfoCol', {
    attrs: {
      "label": "Søker på bakgrunn av *"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": "",
      "rules": [_vm.validateBoolean]
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.applicationModel.isFormalCompetence,
      callback: function ($$v) {
        _vm.$set(_vm.applicationModel, "isFormalCompetence", $$v);
      },
      expression: "applicationModel.isFormalCompetence"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "Realkompetanse",
      "value": false,
      "data-cy": "isFormalCompetenceFalse"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "Formell kompetanse",
      "value": true,
      "data-cy": "isFormalCompetenceTrue"
    }
  })], 1)], 1), _vm.applicationModel.isFormalCompetence === false ? _c('div', {
    staticClass: "px-3"
  }, [_c('h2', [_vm._v(_vm._s(_vm.signupDescriptions.realkompetanse.headerOne))]), _c('p', {
    staticClass: "mt-2"
  }, [_vm._v(" " + _vm._s(_vm.isParagraphOneSummary && _vm.isMobile ? _vm.signupDescriptions.realkompetanse.paragraphOne.slice(0, 100) : _vm.signupDescriptions.realkompetanse.paragraphOne) + " "), _vm.isMobile ? _c('a', {
    on: {
      "click": function ($event) {
        _vm.isParagraphOneSummary = !_vm.isParagraphOneSummary;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.isParagraphOneSummary ? "Vis mer ..." : "Vis mindre") + " ")]) : _vm._e()]), _c('p', [_vm._v(" Du kan lese mer og finne en god oversikt om dette "), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://utdanning.no/tema/nyttig_informasjon/realkompetanse"
    }
  }, [_vm._v("her")])]), _c('h3', {
    staticClass: "mt-4"
  }, [_vm._v(" " + _vm._s(_vm.signupDescriptions.realkompetanse.headerTwo) + " ")]), _c('p', {
    staticClass: "mt-2"
  }, [_vm._v(" " + _vm._s(_vm.isParagraphTwoSummary && _vm.isMobile ? _vm.signupDescriptions.realkompetanse.paragraphTwo.slice(0, 97) : _vm.signupDescriptions.realkompetanse.paragraphTwo) + " "), _vm.isMobile ? _c('a', {
    on: {
      "click": function ($event) {
        _vm.isParagraphTwoSummary = !_vm.isParagraphTwoSummary;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.isParagraphTwoSummary ? "Vis mer ..." : "Vis mindre") + " ")]) : _vm._e()]), _c('h3', {
    staticClass: "mt-4"
  }, [_vm._v(" " + _vm._s(_vm.signupDescriptions.realkompetanse.headerThree) + " ")]), _c('p', {
    staticClass: "mt-4"
  }, [_vm._v(" " + _vm._s(_vm.signupDescriptions.realkompetanse.paragraphThree) + " ")])]) : _vm._e(), _vm.applicationModel.isFormalCompetence === true ? _c('div', {
    staticClass: "px-3"
  }, [_c('h2', [_vm._v(_vm._s(_vm.signupDescriptions.formellkompetanse.headerOne))]), _c('p', {
    staticClass: "mt-2"
  }, [_vm._v(" Du vil under opptakskravene på kurset/studiet på "), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.aof.no"
    }
  }, [_vm._v("www.aof.no")]), _vm._v(" se hva som er det formelle kravet for opptak til dette studiet/kurset. Eksempler på dette kan være fagbrev, generell studiekompetanse, fagskoleutdanning osv. Vi anbefaler deg å lese hva som gjelder for dette spesifikke studiet/kurset på søknadssiden, eller du kan kontakte oss. ")]), _c('h3', {
    staticClass: "mt-4"
  }, [_vm._v(" " + _vm._s(_vm.signupDescriptions.formellkompetanse.headerTwo) + " ")]), _c('p', {
    staticClass: "mt-2"
  }, [_vm._v(" Dokumentasjon kan lastes opp i bunnen av skjema, evt du kan laste de opp på «MinSide» i etterkant. ")])]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }