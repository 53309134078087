var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-radio-group', {
    attrs: {
      "row": "",
      "rules": _vm.question.required ? [_vm.validateBoolean] : []
    },
    on: {
      "change": _vm.onBoolModelChange
    },
    model: {
      value: _vm.boolModel,
      callback: function ($$v) {
        _vm.boolModel = $$v;
      },
      expression: "boolModel"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "Ja",
      "value": "true",
      "data-cy": "yes"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "Nei",
      "value": "false",
      "data-cy": "no"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }