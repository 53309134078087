
import { ApiUpsertApplicationFormResponseDto } from "@/api/generated/Api";
import BaseCard from "@/components/shared/BaseCard.vue";
import BaseInfoCol from "@/components/shared/BaseInfoCol.vue";
import BaseRow from "@/components/shared/BaseRow.vue";
import { useAutoDeepCloneProp } from "@/fragments/useAutoDeepCloneProp";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { validateNotEmpty, validateIsEmail, validateZipCode } from "@/shared/helpers/validationHelpers";
import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "ApplyCourseEmployerInfo",
  components: { BaseCard, BaseInfoCol, BaseRow },
  props: {
    value: {
      type: Object as PropType<ApiUpsertApplicationFormResponseDto>,
      required: true,
    },
    isEmployerEmail: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["input"],
  setup(props, { emit }) {
    const applicationModel = useAutoDeepCloneProp<ApiUpsertApplicationFormResponseDto>(props, "value");

    const emitChanges = () => {
      emit("input", deepCloneObject(applicationModel.value));
    };

    return {
      applicationModel,
      validateNotEmpty,
      validateIsEmail,
      validateZipCode,
      emitChanges,
    };
  },
});
