var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseRow', [_vm.isCitizenship ? _c('BaseInfoCol', {
    attrs: {
      "label": "Statsborgerskap: *",
      "data-cy": "citizenship"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "data-cy": "appForm-country-select",
      "items": _vm.countries,
      "label": "Velg et land",
      "item-text": "name",
      "item-value": "ssbCode",
      "rules": [_vm.validateNotEmpty]
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.applicationModel.citizenship,
      callback: function ($$v) {
        _vm.$set(_vm.applicationModel, "citizenship", $$v);
      },
      expression: "applicationModel.citizenship"
    }
  })], 1) : _vm._e(), _vm.isPersonalNumber ? _c('BaseInfoCol', {
    attrs: {
      "label": "Fødselsnummer: *",
      "data-cy": "personalNumber"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.validSSN],
      "label": "Fødselsnummer eller D-nummer",
      "data-cy": "appForm-SSN-input"
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.applicationModel.personalNumber,
      callback: function ($$v) {
        _vm.$set(_vm.applicationModel, "personalNumber", $$v);
      },
      expression: "applicationModel.personalNumber"
    }
  })], 1) : _vm._e(), _vm.isNorwegianMotherTongue ? _c('BaseInfoCol', {
    attrs: {
      "label": "Morsmål: *",
      "data-cy": "motherTongue"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": "",
      "rules": [_vm.validateBoolean]
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.applicationModel.isNorwegianMotherTongue,
      callback: function ($$v) {
        _vm.$set(_vm.applicationModel, "isNorwegianMotherTongue", $$v);
      },
      expression: "applicationModel.isNorwegianMotherTongue"
    }
  }, [_c('v-radio', {
    attrs: {
      "data-cy": "isNorwegianMotherTongueTrue",
      "label": "Norsk",
      "value": true
    }
  }), _c('v-radio', {
    attrs: {
      "data-cy": "isNorwegianMotherTongueFalse",
      "label": "Annet",
      "value": false
    }
  })], 1)], 1) : _vm._e(), _vm.applicationModel.isNorwegianMotherTongue === false && _vm.isNorwegianSkills ? _c('BaseInfoCol', {
    attrs: {
      "label": "Norskferdigheter: *"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": "",
      "rules": [_vm.validateNotEmpty]
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.applicationModel.norwegianSkillsLevel,
      callback: function ($$v) {
        _vm.$set(_vm.applicationModel, "norwegianSkillsLevel", $$v);
      },
      expression: "applicationModel.norwegianSkillsLevel"
    }
  }, _vm._l(_vm.norwegianSkillOptions, function (currentOption) {
    return _c('v-radio', {
      key: currentOption,
      attrs: {
        "label": currentOption,
        "value": currentOption
      }
    });
  }), 1)], 1) : _vm._e(), _vm.applicationModel.isNorwegianMotherTongue === false && _vm.isNorwegianCertificationLevel ? _c('BaseInfoCol', {
    attrs: {
      "label": "Norskferdigheter, CEFR-nivå: *"
    }
  }, [_c('v-select', {
    attrs: {
      "data-cy": "appForm-norwegianskills-cefr-select",
      "items": _vm.languageLevels,
      "item-text": "name",
      "label": "Ditt CEFR-nivå",
      "rules": [_vm.validateNotEmpty]
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.applicationModel.norwegianSkillsCerfLevel,
      callback: function ($$v) {
        _vm.$set(_vm.applicationModel, "norwegianSkillsCerfLevel", $$v);
      },
      expression: "applicationModel.norwegianSkillsCerfLevel"
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }