var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.showQuestion ? _c('v-textarea', {
    attrs: {
      "label": _vm.question.description,
      "rows": "2",
      "outlined": "",
      "rules": _vm.getQuestionRules(),
      "counter": 200
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.textModel,
      callback: function ($$v) {
        _vm.textModel = $$v;
      },
      expression: "textModel"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }