
import { ApiGetCustomerDto, ApiUpsertApplicationFormResponseDto } from "@/api/generated/Api";
import BaseCard from "@/components/shared/BaseCard.vue";
import BaseInfoCol from "@/components/shared/BaseInfoCol.vue";
import BaseRow from "@/components/shared/BaseRow.vue";
import { useAutoDeepCloneProp } from "@/fragments/useAutoDeepCloneProp";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { validateChecked, validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { defineComponent, PropType, Ref } from "@vue/composition-api";

export default defineComponent({
  name: "ApplyCourseUserInfo",
  components: { BaseCard, BaseInfoCol, BaseRow },
  props: {
    value: {
      type: Object as PropType<ApiUpsertApplicationFormResponseDto>,
      required: true,
    },
    activeUserInfo: {
      type: Object as PropType<ApiGetCustomerDto>,
      required: true,
    },
  },
  emits: ["input"],
  setup(props, { emit }) {
    const applicationModel: Ref<ApiUpsertApplicationFormResponseDto> =
      useAutoDeepCloneProp<ApiUpsertApplicationFormResponseDto>(props, "value");

    const emitChanges = () => {
      emit("input", deepCloneObject(applicationModel.value));
    };

    return {
      applicationModel,
      validateNotEmpty,
      emitChanges,
      validateChecked,
    };
  },
});
