var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseRow', [_c('BaseInfoCol', {
    attrs: {
      "label": "Arbeidsstedets navn: *"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.validateNotEmpty],
      "label": "Arbeidsstedets navn",
      "data-cy": "appForm-employer-name-input"
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.applicationModel.employer.name,
      callback: function ($$v) {
        _vm.$set(_vm.applicationModel.employer, "name", $$v);
      },
      expression: "applicationModel.employer.name"
    }
  })], 1), _c('BaseInfoCol', {
    attrs: {
      "label": "Arbeidsstedets adresse: *"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.validateNotEmpty],
      "label": "Arbeidsstedets adresse",
      "data-cy": "appForm-employer-address-input"
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.applicationModel.employer.address,
      callback: function ($$v) {
        _vm.$set(_vm.applicationModel.employer, "address", $$v);
      },
      expression: "applicationModel.employer.address"
    }
  })], 1), _c('BaseInfoCol', {
    attrs: {
      "label": "Postnummer: *"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.validateNotEmpty, _vm.validateZipCode],
      "label": "Postnummer",
      "data-cy": "appForm-employer-zip-input"
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.applicationModel.employer.zip,
      callback: function ($$v) {
        _vm.$set(_vm.applicationModel.employer, "zip", $$v);
      },
      expression: "applicationModel.employer.zip"
    }
  })], 1), _c('BaseInfoCol', {
    attrs: {
      "label": "Poststed: *"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.validateNotEmpty],
      "label": "Poststed",
      "data-cy": "appForm-employer-city-input"
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.applicationModel.employer.city,
      callback: function ($$v) {
        _vm.$set(_vm.applicationModel.employer, "city", $$v);
      },
      expression: "applicationModel.employer.city"
    }
  })], 1), _vm.isEmployerEmail ? _c('BaseInfoCol', {
    attrs: {
      "label": "E-post til nærmeste overordnet: *"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.validateNotEmpty, _vm.validateIsEmail],
      "label": "E-post til nærmeste overordnet",
      "data-cy": "appForm-employer-email-input"
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.applicationModel.employer.email,
      callback: function ($$v) {
        _vm.$set(_vm.applicationModel.employer, "email", $$v);
      },
      expression: "applicationModel.employer.email"
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }