var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseRow', [_c('BaseInfoCol', {
    attrs: {
      "label": "Fakturamottaker: *"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": "",
      "rules": [_vm.validateBoolean]
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.applicationModel.isCostBearerPrivate,
      callback: function ($$v) {
        _vm.$set(_vm.applicationModel, "isCostBearerPrivate", $$v);
      },
      expression: "applicationModel.isCostBearerPrivate"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "Organisasjon",
      "value": false,
      "data-cy": "costBearerPrivateFalse"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "Meg selv",
      "value": true,
      "data-cy": "costBearerPrivateTrue"
    }
  })], 1)], 1), _vm.applicationModel.isCostBearerPrivate === false ? [_c('CostBearerForm', {
    attrs: {
      "isPurchaserOrganization": true
    },
    on: {
      "updatePurchaserOrganization": _vm.updatePurchaserOrganization
    }
  }), _c('BaseRow', [_c('BaseInfoCol', {
    attrs: {
      "label": "Er det en annen fakturaadresse? *"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": "",
      "rules": [_vm.validateBoolean]
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.hasAnotherInvoiceAddress,
      callback: function ($$v) {
        _vm.hasAnotherInvoiceAddress = $$v;
      },
      expression: "hasAnotherInvoiceAddress"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "Ja",
      "value": true
    }
  }), _c('v-radio', {
    attrs: {
      "label": "Nei",
      "value": false
    }
  })], 1)], 1)], 1), _vm.hasAnotherInvoiceAddress ? _c('CostBearerForm', {
    attrs: {
      "isPurchaserOrganization": false
    },
    on: {
      "updateCostBearerOrganization": _vm.updateCostBearerOrganization
    }
  }) : _vm._e(), _vm.isInvoiceReference ? _c('BaseInfoCol', {
    attrs: {
      "label": ("Fakturareferanse: " + (!!_vm.applicationModel.costBearerOrganization.referenceNumber ? "" : "*")),
      "icon": "mdi-message-question-outline",
      "iconText": _vm.invoiceReferenceText
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": !!_vm.applicationModel.costBearerOrganization.referenceNumber ? [] : [_vm.validateNotEmptyWithCustomMessage(_vm.invoiceReferenceErrorMessage)],
      "label": "Bestillingsnummer",
      "data-cy": "appForm-costBearer-orderNumber-input"
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.applicationModel.costBearerOrganization.orderNumber,
      callback: function ($$v) {
        _vm.$set(_vm.applicationModel.costBearerOrganization, "orderNumber", $$v);
      },
      expression: "applicationModel.costBearerOrganization.orderNumber"
    }
  })], 1) : _vm._e(), _vm.isInvoiceReference ? _c('BaseInfoCol', {
    attrs: {
      "label": ("Fakturareferanse: " + (!!_vm.applicationModel.costBearerOrganization.orderNumber ? "" : "*")),
      "icon": "mdi-message-question-outline",
      "iconText": _vm.invoiceReferenceText
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": !!_vm.applicationModel.costBearerOrganization.orderNumber ? [] : [_vm.validateNotEmptyWithCustomMessage(_vm.invoiceReferenceErrorMessage)],
      "label": "Deres referanse",
      "data-cy": "appForm-costBearer-referenceNumber-input"
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.applicationModel.costBearerOrganization.referenceNumber,
      callback: function ($$v) {
        _vm.$set(_vm.applicationModel.costBearerOrganization, "referenceNumber", $$v);
      },
      expression: "applicationModel.costBearerOrganization.referenceNumber"
    }
  })], 1) : _vm._e()] : _vm._e(), _vm.applicationModel.isCostBearerPrivate ? [_vm.activeUserInfo ? _c('BaseRow', {
    attrs: {
      "data-cy": "isCostBearerPrivate"
    }
  }, [_c('BaseInfoCol', {
    attrs: {
      "label": "Navn:"
    }
  }, [_vm._v(" " + _vm._s(_vm.activeUserInfo.customer.firstName) + " " + _vm._s(_vm.activeUserInfo.customer.lastName) + " ")]), _c('BaseInfoCol', {
    attrs: {
      "label": "Fakturaadresse:"
    }
  }, [_vm._v(" " + _vm._s(_vm.activeUserInfo.postAddress1) + " ")]), _c('BaseInfoCol', {
    attrs: {
      "label": "Postnummer:"
    }
  }, [_vm._v(" " + _vm._s(_vm.activeUserInfo.postZip) + " ")]), _c('BaseInfoCol', {
    attrs: {
      "label": "Poststed:"
    }
  }, [_vm._v(" " + _vm._s(_vm.activeUserInfo.postCity) + " ")]), _c('BaseInfoCol', {
    attrs: {
      "label": "Mobilnummer:"
    }
  }, [_vm._v(" " + _vm._s(_vm.activeUserInfo.mobileNumber) + " ")]), _c('BaseInfoCol', {
    attrs: {
      "label": "E-post:"
    }
  }, [_vm._v(" " + _vm._s(_vm.activeUserInfo.email) + " ")])], 1) : _vm._e(), _c('BaseRow', [_vm.isInstallments && _vm.filterInstallmentOptions.length ? _c('BaseInfoCol', {
    attrs: {
      "label": "Avdragsbetaling: *"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": "",
      "rules": [_vm.validateNotEmpty]
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.applicationModel.costBearerPrivate.installmentInterval,
      callback: function ($$v) {
        _vm.$set(_vm.applicationModel.costBearerPrivate, "installmentInterval", $$v);
      },
      expression: "applicationModel.costBearerPrivate.installmentInterval"
    }
  }, _vm._l(_vm.filterInstallmentOptions, function (currentOption) {
    return _c('v-radio', {
      key: currentOption.value,
      attrs: {
        "data-cy": currentOption.label,
        "label": currentOption.label,
        "value": currentOption.value
      }
    });
  }), 1)], 1) : _vm._e()], 1)] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }