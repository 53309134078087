var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.oneLine ? _c('v-col', _vm._b({
    staticClass: "d-flex align-center"
  }, 'v-col', _vm.cols, false), [_vm.label ? _c('span', {
    staticClass: "pr-3 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e(), _c('span', [_vm._t("default")], 2)]) : _c('v-col', _vm._b({}, 'v-col', _vm.cols, false), [_vm.label ? _c('span', {
    staticClass: "pr-3 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e(), _vm.subLabel ? _c('span', {
    staticClass: "body-2 font-weight-light"
  }, [_vm._v(" " + _vm._s(_vm.subLabel) + " ")]) : _vm._e(), _vm.icon ? _c('v-tooltip', {
    attrs: {
      "right": "",
      "color": "info"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-icon', _vm._g(_vm._b({
          attrs: {
            "color": "primary"
          }
        }, 'v-icon', attrs, false), on), [_vm._v(" " + _vm._s(_vm.icon) + " ")])];
      }
    }], null, false, 918248846)
  }, [_c('span', [_vm._v(_vm._s(_vm.iconText))])]) : _vm._e(), _c('span', [_vm._t("default")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }