import {
  ApiGetApplicationFormTemplateDto,
  ApiGetMinSideCourseDto,
  ApiUpsertApplicationFormResponseDto,
} from "@/api/generated/Api";

export const setInitialApplicationFormTemplate = (): ApiGetApplicationFormTemplateDto => {
  return {
    id: "1",
    isActive: false,
    appliedInCourses: null,
    inserted: "",
    insertedBy: 0,
    updated: "",
    updatedBy: 0,
    name: null,
    isVocationalSchool: false,
    isCitizenship: false,
    isPersonalNumber: false,
    isNorwegianMotherTongue: false,
    isNorwegianCertificationLevel: false,
    isNorwegianSkills: false,
    isCompetence: false,
    isEmployer: false,
    isEmployerEmail: null,
    isCostBearer: false,
    isInstallments: false,
    isMonthly: null,
    isSemiAnnually: null,
    isQuarterly: null,
    isInvoiceReference: null,
    isNewsletter: false,
    isUnionized: false,
    isMemberOrganization: false,
    isRegionalOffices: null,
    regulationFileId: null,
    additionalQuestions: null,
  };
};

export const setInitialCourse = (): ApiGetMinSideCourseDto => {
  return {
    id: 0,
    curriculumId: 0,
    coursePractice: false,
    participantCapacity: 0,
    mainCourseArea: null,
    status: null,
    courseName: null,
    externalTitle: null,
    reasonForStatusChange: null,
    useLearningPlatform: false,
    courseLocation: undefined,
    studyLocationId: null,
    cancellationDeadline: null,
    startDate: "",
    endDate: "",
    isTimeWebVisible: false,
    hoursWithInstructor: 0,
    hoursWithoutInstructor: null,
    hoursOnline: null,
    hoursSelfStudy: null,
    completedScheduledHours: 0,
    remainingScheduledHours: 0,
    totalScheduledHours: 0,
    courseSupervisorId: 0,
    organizerOrganizationId: 0,
    ownerOrganizationId: 0,
    lecturerId: 0,
    contactId: 0,
    kasId: null,
    enrollmentDeadline: null,
    unenrollmentDeadline: null,
    minimumParticipants: 0,
    maxParticipants: 0,
    participantSubscribedCount: null,
    financedByOrganizationIds: null,
    isOrderable: false,
    plan: undefined,
    additionalTeachingMaterialIds: null,
    curriculumTeachingMaterialIds: null,
    tests: null,
    comments: null,
    economy: undefined,
    webDescription: undefined,
    teachingMethod: null,
    mainCourseId: null,
    facilitationCourseId: null,
    inserted: "",
    insertedBy: 0,
    updated: "",
    updatedBy: 0,
  };
};

export const getInitialApplicationModel: () => ApiUpsertApplicationFormResponseDto = () => {
  return {
    templateId: "",
    employer: {
      name: "",
      address: "",
      email: "",
      zip: "",
      city: "",
    },
    isCostBearerPrivate: null,
    costBearerPrivate: {
      installmentInterval: null,
      installmentType: null,
      hasVerifiedContactInfo: false,
    },
    costBearerOrganization: {
      organizationNumber: "",
      name: "",
      homePage: "",
      address: "",
      city: "",
      zip: "",
      country: "",
      mobileNumber: "",
      email: "",
      isPurchaser: true,
      orderNumber: "",
      referenceNumber: "",
    },
    purchaser: {
      organizationNumber: "",
      name: "",
      homePage: "",
      address: "",
      city: "",
      zip: "",
      country: "",
      mobileNumber: "",
      email: "",
    },
    isFormalCompetence: false,
    isNorwegianCitizen: null,
    citizenship: "",
    isNorwegianMotherTongue: null,
    norwegianSkillsLevel: null,
    norwegianSkillsCerfLevel: null,
    personalNumber: "",
    isNewsletter: null,
    isUnionized: true,

    memberOrganizationId: null,
    courseRegulationsAccepted: false,

    regulationsFileId: null,
    additionalQuestions: [],
  };
};
