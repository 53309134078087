var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.hasAdditionalQuestions ? _c('div', [_vm._l(_vm.questions, function (question) {
    return [_vm.showQuestion(question) ? _c('BaseRow', {
      key: question.id
    }, [_c('BaseInfoCol', {
      key: question.id,
      staticClass: "pb-0",
      attrs: {
        "label": ((question.description) + " " + (question.required ? "*" : ""))
      }
    }, [_c(_vm.questionComponent[question.type], {
      tag: "component",
      attrs: {
        "question": question,
        "value": _vm.value,
        "data-cy": ("additionalQuestion-" + (question.questionNumber)),
        "showQuestion": _vm.showQuestion(question),
        "additionalQuestions": _vm.questions
      },
      on: {
        "input": _vm.emitChangesChild
      }
    })], 1)], 1) : _vm._e()];
  })], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }