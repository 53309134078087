var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.questionAlternatives && _vm.questionAlternatives.length ? _c('div', [_c('v-radio-group', {
    attrs: {
      "rules": _vm.question.required ? [_vm.validateNotEmpty] : []
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.multipleChoiceModel,
      callback: function ($$v) {
        _vm.multipleChoiceModel = $$v;
      },
      expression: "multipleChoiceModel"
    }
  }, _vm._l(_vm.questionAlternatives, function (questionAlternative) {
    return _c('v-radio', {
      key: questionAlternative.alternativeNumber,
      attrs: {
        "label": questionAlternative.description,
        "value": questionAlternative.description,
        "data-cy": ("questionAlternative-" + (questionAlternative.alternativeNumber))
      }
    });
  }), 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }