import { ApiUpsertApplicationFormResponseDto } from "@/api/generated/Api";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { computed, onMounted, Ref, watch } from "@vue/composition-api";

export const useAutoUpdateAdditionalQuestionModel = (
  modelRef: Ref<string>,
  applicationModel: Ref<ApiUpsertApplicationFormResponseDto>,
  questionId: string,
  emit: (event: string, ...args: any[]) => void
) => {
  const getModelQuestionById = (id: string) => {
    if (!applicationModel.value.additionalQuestions) {
      throw new Error(`No additional questions found in application model`);
    }
    const element = applicationModel.value.additionalQuestions.find((current) => current.questionId === id);
    if (element === undefined || element === null) {
      throw new Error(`Question with id ${id} not found`);
    }
    return element;
  };

  onMounted(() => {
    if (!applicationModel.value.additionalQuestions) {
      throw new Error(`No additional questions found in application model`);
    }
    // watch on value needs computed
    watch(
      computed(() => applicationModel.value.additionalQuestions),
      () => {
        modelRef.value = getModelQuestionById(questionId).response ?? "";
      },
      { immediate: true, deep: true }
    );
  });

  const emitChanges = () => {
    const newState = {
      ...applicationModel.value,
      additionalQuestions: applicationModel.value.additionalQuestions?.map((current) => ({
        ...current,
        response: current.questionId === questionId ? modelRef.value : current.response,
      })),
    };
    emit("input", deepCloneObject(newState));
  };

  return { emitChanges };
};
