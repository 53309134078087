
import {
  ApiUpsertApplicationFormResponseDto,
  ApiUpsertAppFormResponseAdditionalQuestionDto,
  ApiApplicationFormTemplateAdditionalQuestionDto,
} from "@/api/generated/Api";
import { useAutoDeepCloneProp } from "@/fragments/useAutoDeepCloneProp";
import { useAutoUpdateAdditionalQuestionModel } from "@/fragments/useAutoUpdateAdditionalQuestionModel";
import { BooleanQuestionType } from "@/shared/types/questions.types";
import { defineComponent, PropType, Ref, ref } from "@vue/composition-api";
import { validateBoolean } from "@/shared/helpers/validationHelpers";

export default defineComponent({
  name: "BooleanQuestion",
  props: {
    question: {
      type: Object as PropType<BooleanQuestionType>,
      required: true,
    },
    value: {
      type: Object as PropType<ApiUpsertApplicationFormResponseDto>,
      required: true,
    },
    additionalQuestions: {
      type: Array as PropType<ApiApplicationFormTemplateAdditionalQuestionDto[]>,
      required: true,
    },
  },
  emits: ["change", "input"],
  setup(props, { emit }) {
    const applicationModel: Ref<ApiUpsertApplicationFormResponseDto> =
      useAutoDeepCloneProp<ApiUpsertApplicationFormResponseDto>(props, "value");
    const boolModel = ref(""); // backend only wants strings...

    const { emitChanges } = useAutoUpdateAdditionalQuestionModel(boolModel, applicationModel, props.question.id, emit);

    const onBoolModelChange = () => {
      if (boolModel.value === "false") {
        // handle when user selected false on questions that depends on this boolean question
        const questionIds = getQuestionIdsDependOnBooleanQuestionType(props.question.id, props.additionalQuestions);
        // depended questions' response will be mapped to empty string
        applicationModel.value.additionalQuestions = mapAdditionalQuestionWithEmptyResponse(
          questionIds,
          applicationModel.value.additionalQuestions ?? undefined
        );
      }
      emitChanges();
    };

    const getQuestionIdsDependOnBooleanQuestionType = (
      booleanQuestionId: string,
      additionalQuestionsTemplate: ApiApplicationFormTemplateAdditionalQuestionDto[]
    ) => {
      return additionalQuestionsTemplate
        ?.filter((question) => question.displayIfQuestionIdIsTrue === booleanQuestionId)
        .reduce<string[]>((previous, { id }) => {
          return [...previous, id];
        }, []);
    };

    const mapAdditionalQuestionWithEmptyResponse = (
      questionIds: string[],
      additionalQuestionsModel?: ApiUpsertAppFormResponseAdditionalQuestionDto[]
    ) => {
      return additionalQuestionsModel?.map((current) => ({
        ...current,
        response: questionIds.includes(current.questionId) ? "" : current.response,
      }));
    };

    return {
      boolModel,
      onBoolModelChange,
      validateBoolean,
    };
  },
});
