
import {
  ApiApplicationFormTemplateAdditionalQuestionDto,
  ApiUpsertApplicationFormResponseDto,
} from "@/api/generated/Api";
import BaseCard from "@/components/shared/BaseCard.vue";
import BaseInfoCol from "@/components/shared/BaseInfoCol.vue";
import BaseRow from "@/components/shared/BaseRow.vue";
import { useAutoDeepCloneProp } from "@/fragments/useAutoDeepCloneProp";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { QuestionValueType } from "@/shared/types/questions.types";
import { computed, defineComponent, PropType, Ref } from "@vue/composition-api";
import { Component } from "vue";
import FileQuestion from "@/components/courseApply/additionalQuestions/FileQuestion.vue";
import TextQuestion from "@/components/courseApply/additionalQuestions/TextQuestion.vue";
import BooleanQuestion from "@/components/courseApply/additionalQuestions/BooleanQuestion.vue";
import MultipleChoiceQuestion from "@/components/courseApply/additionalQuestions/MutipleChoiceQuestion.vue";

export default defineComponent({
  name: "ApplyCourseAdditionalQuestions",
  components: { BaseCard, BaseInfoCol, BaseRow },
  props: {
    value: {
      type: Object as PropType<ApiUpsertApplicationFormResponseDto>,
      required: true,
    },
    questions: {
      type: Array as PropType<ApiApplicationFormTemplateAdditionalQuestionDto[]>,
      required: true,
    },
  },
  emits: ["input"],
  setup(props, { emit }) {
    const applicationModel: Ref<ApiUpsertApplicationFormResponseDto> =
      useAutoDeepCloneProp<ApiUpsertApplicationFormResponseDto>(props, "value");

    const hasAdditionalQuestions = computed(
      () => props.questions?.length && applicationModel.value.additionalQuestions?.length
    );

    const emitChangesChild = (newApplicationModel: ApiUpsertApplicationFormResponseDto) => {
      emit("input", deepCloneObject(newApplicationModel));
    };

    const getQuestionResponseById = (id: string) => {
      const questionResponse = applicationModel.value.additionalQuestions?.find(
        (question) => question.questionId === id
      )?.response;
      // response has string/null type
      return questionResponse === "true";
    };

    const showQuestion = (question: ApiApplicationFormTemplateAdditionalQuestionDto) => {
      return (
        question.displayIfQuestionIdIsTrue === undefined ||
        (question.displayIfQuestionIdIsTrue && getQuestionResponseById(question.displayIfQuestionIdIsTrue))
      );
    };

    return {
      questionComponent,
      hasAdditionalQuestions,
      emitChangesChild,
      showQuestion,
    };
  },
});

const questionComponent: { [type in QuestionValueType]?: Component } = {
  [QuestionValueType.File]: FileQuestion,
  [QuestionValueType.Text]: TextQuestion,
  [QuestionValueType.Boolean]: BooleanQuestion,
  [QuestionValueType.MultipleChoice]: MultipleChoiceQuestion,
};
