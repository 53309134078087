var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.showAddFileModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": "1500px"
    }
  }, [_c('v-form', {
    ref: "connectForm",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('BaseCard', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Last opp filer "), _c('div', {
          staticClass: "body-2 font-weight-light"
        }, [_vm._v("Gyldige filformater for opplastning er: PDF, PNG, JPEG, JPG")])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('v-btn', {
          staticClass: "px-6",
          attrs: {
            "color": ""
          },
          on: {
            "click": function ($event) {
              _vm.showAddFileModal = false;
            }
          }
        }, [_vm._v(" Lukk ")]), _c('v-btn', {
          staticClass: "px-6",
          attrs: {
            "color": "primary"
          },
          on: {
            "click": _vm.addModalFile
          }
        }, [_vm._v(" Legg til ")])];
      },
      proxy: true
    }], null, false, 2089946187)
  }, [_c('BaseRow', [_c('BaseInfoCol', {
    attrs: {
      "label": "Fil",
      "subLabel": "Filnavn kan kun inneholde tall og bokstaver, og ingen spesialtegn",
      "customCols": _vm.customCols
    }
  }, [_c('v-file-input', {
    attrs: {
      "rules": [_vm.validateNotEmpty],
      "show-size": "",
      "counter": "",
      "chips": "",
      "label": "Last opp filer"
    },
    model: {
      value: _vm.modalSelectedFile,
      callback: function ($$v) {
        _vm.modalSelectedFile = $$v;
      },
      expression: "modalSelectedFile"
    }
  })], 1), _c('BaseInfoCol', {
    attrs: {
      "label": "Filkategori",
      "customCols": _vm.customCols
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Velg Kategori",
      "items": _vm.fileCategories,
      "item-text": "text",
      "rules": [_vm.validateNotEmpty]
    },
    model: {
      value: _vm.modalSelectedCategory,
      callback: function ($$v) {
        _vm.modalSelectedCategory = $$v;
      },
      expression: "modalSelectedCategory"
    }
  })], 1), _c('BaseInfoCol', {
    attrs: {
      "label": "Beskrivelse",
      "customCols": _vm.customCols
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Fil Beskrivelse",
      "name": "description",
      "counter": "50",
      "type": "text",
      "hide-details": "",
      "required": ""
    },
    model: {
      value: _vm.modalFileDescription,
      callback: function ($$v) {
        _vm.modalFileDescription = $$v;
      },
      expression: "modalFileDescription"
    }
  })], 1)], 1)], 1)], 1)], 1) : _vm._e(), _c('BaseRow', [_c('BaseInfoCol', [_c('v-btn', {
    staticClass: "mr-4",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.openNewFileModal
    }
  }, [_vm._v(" Legg til ny fil ")])], 1)], 1), _vm.courseFiles.length ? _c('BaseRow', [_c('BaseInfoCol', {
    attrs: {
      "label": "Filer lagt ved søknad",
      "oneLine": true
    }
  }, _vm._l(_vm.courseFiles, function (file) {
    return _c('v-chip', {
      key: file.id,
      staticClass: "mr-2 mb-2",
      attrs: {
        "close": ""
      },
      on: {
        "click:close": function ($event) {
          return _vm.removeFile(file);
        }
      }
    }, [_vm._v(" " + _vm._s(file.file.name) + " ")]);
  }), 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }