
import { api } from "@/api/api";
import {
  ApiGetMemberOrganizationDto,
  ApiGetMemberOrganizationFeatureSettingDto,
  ApiUpsertApplicationFormResponseDto,
} from "@/api/generated/Api";
import BaseCard from "@/components/shared/BaseCard.vue";
import BaseInfoCol from "@/components/shared/BaseInfoCol.vue";
import BaseRow from "@/components/shared/BaseRow.vue";
import { useAutoDeepCloneProp } from "@/fragments/useAutoDeepCloneProp";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { validateBoolean, validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { localeSortByProperty } from "@/shared/helpers/arrayHelpers";
import { defineComponent, onMounted, PropType, ref, Ref, computed, watch } from "@vue/composition-api";
import { debounceHelper } from "@/shared/debounce";
import { log } from "logrocket";

interface MemberOrganization {
  id: number;
  name?: string | null;
}

export default defineComponent({
  name: "ApplyCourseUnion",
  components: { BaseCard, BaseInfoCol, BaseRow },
  props: {
    value: {
      type: Object as PropType<ApiUpsertApplicationFormResponseDto>,
      required: true,
    },
    isMemberOrganization: {
      type: Boolean,
      required: true,
    },
    isRegionalOffices: {
      type: Boolean,
      default: false,
    },
    isDivisions: {
      type: Boolean,
      default: false,
    },
    activeFeatureSettings: {
      type: Array as PropType<ApiGetMemberOrganizationFeatureSettingDto[]>,
      default: false,
    },
  },
  emits: ["input"],
  setup(props, { emit }) {
    const applicationModel: Ref<ApiUpsertApplicationFormResponseDto> =
      useAutoDeepCloneProp<ApiUpsertApplicationFormResponseDto>(props, "value");
    const memberOrganizations = ref<ApiGetMemberOrganizationDto[]>([]);
    const parentMemberOrganizationMap = ref<Record<number, MemberOrganization[]>>();
    const activeMainOffices = ref<MemberOrganization[]>([]);
    const selectedMainOfficeId = ref<number>(0);
    const selectedRegionalOfficeId = ref<number>();
    const selectedLocalOfficeId = ref<number>();
    const officeDivisionInput = ref<string>("");
    const hasMultiLevel = ref<boolean>(false);
    const memberOrgLocalOffices = ref<ApiGetMemberOrganizationDto[]>([]);
    const loadingLocalOffices = ref<boolean>(false);

    const checkFeatureSettings = (id: number) => {
      const memberOrganization = props.activeFeatureSettings.find((x) => x.organizationId === id);

      if (memberOrganization && memberOrganization.hasMultiLevelMemberOrganization) {
        hasMultiLevel.value = true;
        getMemberOrgLocalOffices(memberOrganization.organizationId);
        loadingLocalOffices.value = true;
        return;
      }

      hasMultiLevel.value = false;
      memberOrgLocalOffices.value = [];
    };

    const getMemberOrgLocalOffices = async (id: number) => {
      memberOrgLocalOffices.value = (await api.organization.getMemberOrganizationLocalOfficesAsync(id)).data;

      loadingLocalOffices.value = false;
    };

    watch(officeDivisionInput, async () => {
      await debounceHelper.debounce("divisionInput", 100);
      emitChanges();
    });

    watch(selectedMainOfficeId, () => {
      selectedRegionalOfficeId.value = undefined;
      selectedLocalOfficeId.value = undefined;
      checkFeatureSettings(selectedMainOfficeId.value);
      emitChanges();
    });

    const showRegionalOffice = computed(
      () => props.isMemberOrganization && props.isRegionalOffices && activeRegionalOffices.value
    );
    const showDivisions = computed(
      () => props.isMemberOrganization && props.isDivisions && memberOrgLocalOffices.value
    );

    const getSeletedMainOfficeId = (selectedId: number) => (selectedId === 0 ? undefined : selectedId);

    const emitChanges = () => {
      //isRegionalOffices from appform Template
      const hasActiveRegionalOffices = props.isRegionalOffices && activeRegionalOffices.value;

      applicationModel.value.memberOrganizationId = hasActiveRegionalOffices
        ? selectedRegionalOfficeId.value
        : getSeletedMainOfficeId(selectedMainOfficeId.value);

      //isDivisions from appform Template - need to have memberOrganizationId as local office id
      //if dropdown exists
      const hasActiveDivisions = props.isDivisions && memberOrgLocalOffices.value.length > 0;

      applicationModel.value.memberOrganizationId = hasActiveDivisions
        ? selectedLocalOfficeId.value
        : hasActiveRegionalOffices
        ? selectedRegionalOfficeId.value
        : getSeletedMainOfficeId(selectedMainOfficeId.value);

      //if dropdown does not exist memberOrganizationId is the main office id
      // and division name is the input value
      applicationModel.value.memberOrganizationDivisionName = props.isDivisions
        ? !hasActiveDivisions
          ? officeDivisionInput.value
          : memberOrgLocalOffices.value?.find((s: any) => s.id === selectedLocalOfficeId.value)?.name
        : null;

      emit("input", deepCloneObject(applicationModel.value));
    };

    const loadMemberOrganizations = async () => {
      memberOrganizations.value = (await api.organization.getMemberOrganizationsAsync()).data
        ?.sort(localeSortByProperty("name"))
        .filter((memberOrganization) => memberOrganization.isActive);

      parentMemberOrganizationMap.value = memberOrganizations.value.reduce<Record<number, MemberOrganization[]>>(
        (previous, { parentId, id, name }) => {
          if (parentId === null || parentId === undefined || parentId === 1) {
            return previous;
          }
          const parentOrganization = { id, name };
          const childOrganizations = previous[parentId] ?? [];
          // lookup map with parentId as index which contains child object
          return {
            ...previous,
            [parentId]: [...childOrganizations, parentOrganization],
          };
        },
        {}
      );

      const getFilteredMemberOrganizations = () => {
        if (props.isRegionalOffices) {
          return memberOrganizations.value.filter(
            (memberOrganization) => !memberOrganization.parentId || memberOrganization.parentId === 1
          );
        }
        return memberOrganizations.value.filter((memberOrganization) => !memberOrganization.parentId);
      };

      activeMainOffices.value = getFilteredMemberOrganizations().reduce<MemberOrganization[]>(
        (previous, { id, name }) => {
          const parentOrganization = { id, name };
          return [...previous, parentOrganization];
        },
        []
      );
    };

    const activeRegionalOffices = computed(() => {
      return parentMemberOrganizationMap.value
        ? parentMemberOrganizationMap.value[selectedMainOfficeId.value]
        : undefined;
    });

    onMounted(async () => {
      await loadMemberOrganizations();
    });

    return {
      applicationModel,
      officeDivisionInput,
      showRegionalOffice,
      showDivisions,
      validateNotEmpty,
      emitChanges,
      validateBoolean,
      activeMainOffices,
      activeRegionalOffices,
      selectedMainOfficeId,
      selectedRegionalOfficeId,
      selectedLocalOfficeId,
      hasMultiLevel,
      memberOrgLocalOffices,
      loadingLocalOffices,
    };
  },
});
