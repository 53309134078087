
import { computed, defineComponent, PropType } from "@vue/composition-api";

const defaultColsOneLine = {
  cols: 12,
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
};

const defaultCols = {
  cols: 12,
  xs: 12,
  sm: 12,
  md: 6,
  lg: 4,
  xl: 3,
};

export default defineComponent({
  name: "BaseInfoCol",
  props: {
    label: {
      type: String,
      required: false,
    },
    subLabel: {
      type: String,
      default: null,
    },
    oneLine: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    iconText: {
      type: String,
      default: null,
    },
    customCols: {
      type: Object as PropType<Record<string, number>>,
      default: () => ({}),
    },
  },
  setup(props) {
    const selectDefaultCols = props.oneLine ? defaultColsOneLine : defaultCols;
    const cols = computed(() => ({ ...selectDefaultCols, ...props.customCols }));
    return {
      cols,
    };
  },
});
